.sing .container[data-v-432066ca] {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sing .container .singlist[data-v-432066ca] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.sing .container .singlist-item[data-v-432066ca] {
  width: calc(50% - 1rem);
  padding: 0.4rem;
  border: 0.01rem solid #eeefef;
  margin-bottom: 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2rem;
}
.sing .container .singlist-item .pic[data-v-432066ca] {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.4rem;
}
.sing .container .singlist-item-info[data-v-432066ca] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.sing .container .singlist-item-info .name[data-v-432066ca] {
  font-size: var(--f24);
  color: #000000;
  margin-bottom: 0.16rem;
}
.sing .container .singlist-item-info .person[data-v-432066ca] {
  font-size: var(--f16);
  color: #666666;
}
.sing .container .singlist-item-info .audio[data-v-432066ca] {
  margin-top: 0.24rem;
  cursor: pointer;
}
.sing .container .singlist-item-box[data-v-432066ca] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sing .container .singlist-item[data-v-432066ca]:nth-last-of-type(-n + 2) {
  margin-bottom: unset;
}
.sing .container .singlist-item[data-v-432066ca]:hover {
  border-bottom: 0.02rem solid #bd0100;
  -webkit-box-shadow: 0 0 0.2rem 0 rgba(196, 196, 196, 0.32);
  box-shadow: 0 0 0.2rem 0 rgba(196, 196, 196, 0.32);
}